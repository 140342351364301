export const defaultTags = [
  '__________WAS?__________',
  'ABLAGERUNG',
  'BEWUCHS',
  'BRÜCKE',
  'DIVERSE ABLAGERUNGEN',
  'DURCHLASS',
  'GESCHIEBEANSAMMLUNG',
  'HANGRUTSCH',
  'HOLZABLAGERUNG',
  'HOLZERNTEABFÄLLE',
  'MÜLLANSAMMLUNG',
  'QUERHINDERNIS',
  'QUERHINDERNISSE',
  'RÜCKHALTEBECKEN',
  'STRAUCHSCHNITTABLAGERUNGEN',
  'SCHWEMMHOLZANSAMMLUNG',
  'TOTHOLZ',
  'UFEREROSION',
  'UMGESTÜRZTER BAUM',
  'UMGESTÜRZTE BÄUME',
  'VERBAUUNG',
  'VERKLAUSUNG',
  'WILDBACHSPERRE',
  'WILDBACHVERBAUUNG',
  'WURZELSTOCK',
  '__________WIE?__________',
  'BESCHÄDIGT',
  'KLEINDIMENSIONIERT',
  'LEICHT',
  'STARK',
  'UNTERSPÜLT',
  'VERLANDET',
  'VERSTOPFT',
  '__________WO?__________',
  'LINKS',
  'LINKSUFRIG',
  'LINKSSEITIG',
  'RECHTS',
  'RECHTSUFRIG',
  'RECHTSSEITIG',
  'IM ABFLUSSBEREICH',
  'IM BACHBETT',
  'IM NAHEN UFERBEREICH',
  'IM QUELLGEBIET',
  '___BENUTZERDEFINIERT___'
];
