import { Notification } from '../aureliaComponents/notification-widget/notification-widget';

export const changelogNotifications: Array<Notification> = [
  {
    date: new Date('2023-07-10'),
    title: 'Neue Startseite',
    message: `
    <ul style="padding-left: 15px; margin-top: -20px;">
    <li>Überblick über die zuletzt geöffneten Objekte (und je nach Modul, ggf. Berichte)
    <li>Diverse schnelle Editiermöglichkeiten über Drei-Punkt-Menü zugänglich
    <li>Option zur Festlegung von Favoriten für jeden Benutzer
    </ul>
    Wenn Sie nicht überzeugt sind, können Sie jederzeit zur bisherigen Startseite zurückkehren.
    <a href="mailto:support@recordit.at?subject=Umstellung%20auf%20bisherige%20Startseite">Kontaktieren Sie uns dazu einfach per Mail! (hier klicken)</a>
    `,
    imgSrc: './img/changelog/startseite.png'
  },
  {
    date: new Date('2023-04-05'),
    title: 'Via Onlinesuche',
    message: `∙ Alle Datumsgruppen sind ohne Zeitverzögerung aufklappbar ➔ weitere Daten werden erst bei Klick auf Bild geladen
    ∙ Button zum Aufklappen aller angezeigten Gruppen
    ∙ Suche im Onlinemodus bezieht nun alle Bilder aller Seiten mit ein, aufklappen ist nicht notwendig
    ∙ Erweiterte Filtermöglichkeiten für Parameter (Dropdowns, Radiobuttons, Zahlen etc.)`,
    imgSrc: './img/changelog/via_online_search.png'
  },
  {
    date: new Date('2023-04-05'),
    title: 'Herunterladen von Bildern',
    message:
      'Bilder können jetzt original und bearbeitet, sowie mit etwaigen Markierungen heruntergeladen werden.',
    imgSrc: './img/changelog/picture_download_button.png'
  },
  {
    date: new Date('2022-09-06'),
    title: 'Neue Objektbilder und Titelbilder für Objekte',
    message:
      'Wie schon von Projektbildern (in den Berichtparametern) bekannt, gibt es nun auch auf Objektebene die Möglichkeit, Bilder zu speichern. Die Bilder können in Berichten verwendet werden (Anpassung der Vorlagen erforderlich!) und auch als Titelbild in der App angezeigt werden. So können Objekte auch optisch in der Ansicht schneller gefunden werden.',
    imgSrc: './img/changelog/objektbilder/Objektparameter.png'
  },
  {
    date: new Date('2022-09-06'),
    title: 'Verorten auf Objektbildern auch möglich',
    message:
      'Auch das Verorten ist auf den neu hinzugefügten Objektbildern möglich. Beim Verorten werden die Projektbilder (auf Berichtebene) und die Objektbilder nun getrennt dargestellt.\nWichtig: für die Verwendung der neuen Objektbilder im Bericht müssen Ihre Berichtvorlagen an die neuen Funktionalitäten angepasst werden!',
    imgSrc: './img/changelog/objektbilder/Markieren_auf_Objektbildern.png'
  },
  {
    date: new Date('2021-08-17'),
    title: 'Inspect Schnellauswahlbuttons',
    message:
      'Beim Aufnehmen eines Bildes in der RecordIT Inspect kann jenes nun auch über Schnellauswahlbuttons zugeordnet werden, die den Prozess der Bildaufnahme zeitlich deutlich verkürzen.',
    imgSrc: './img/changelog/inspect_schnellauswahl.png'
  },
  {
    date: new Date('2021-06-02'),
    title: 'Vereinheitlichung der Objektübersicht',
    message:
      'Alle Objekte werden jetzt gesammelt auf einer Seite angezeigt. Dadurch vereinfacht sich die Navigation, es gibt nicht mehr für jedes Modul eine eigene Ansicht und Objekte können nun mehrere Berichte verschiedenen Typs enthalten.',
    imgSrc: './img/changelog/objektuebersicht.png'
  },
  {
    date: new Date('2021-06-02'),
    title: 'Berichtstyp als Farbbalken',
    message:
      'Der Berichtstyp wird farbig gekennzeichnet. Die Farben entsprechen dabei den jeweiligen recordIT-Modulfarben.',
    imgSrc: './img/changelog/berichttyp_farbbalken.png'
  },
  {
    date: new Date('2021-06-02'),
    title: 'Filter nach Berichtstyp',
    message:
      'Innerhalb der Objekt- und der Berichtübersicht kann nach Berichttyp gefiltert werden. Standardmäßig werden alle Objekte bzw. Berichte angezeigt.',
    imgSrc: './img/changelog/berichttypfilter.png'
  },
  {
    date: new Date('2021-06-02'),
    title: 'Wechsel zwischen Berichtliste u. Via-Ansicht',
    message:
      'Um zwischen Berichtliste u. Via-Ansicht zu wechseln, benutzen Sie bitte die entsprechenden Buttons. Das Straßensymbol wechselt in die Via-Ansicht des Objekts, an der gleichen Stelle erscheint wiederum der Button um zur Berichtsliste zurückzuwechseln.',
    imgSrc: './img/changelog/via_thing_list.png'
  }
];
