/** @type {Array<TDefaultProcessTaskMeasurePointReadingProperty>} */
export const defaultProcessTaskMeasurePointReadingProperties = [
  {
    name: 'Messgerät',
    type: 'dropdown',
    value: 'I',
    alwaysVisible: true,
    choices: ['I', 'II'],
    order: 1
  },
  {
    name: 'Digits',
    type: 'nummer',
    value: '',
    alwaysVisible: true,
    choices: [],
    order: 2,
    focusProperty: true,
    required: true
  },
  {
    name: '°C',
    type: 'nummer',
    value: '',
    alwaysVisible: true,
    choices: [],
    order: 3
  },
  {
    name: '% RF',
    type: 'nummer',
    value: '',
    alwaysVisible: true,
    choices: [],
    order: 4
  }
];

/**
 * @typedef {Object} TDefaultProcessTaskMeasurePointReadingPropertyExtension
 * @property {number} order
 * @property {boolean} [focusProperty] - set this to true to focus this property when the reading gets focused
 * @property {boolean} [required]
 */

/**
 * @typedef {import('../../../../common/src/Types/DefaultPropertyConfig').TDefaultPropertyConfig & TDefaultProcessTaskMeasurePointReadingPropertyExtension} TDefaultProcessTaskMeasurePointReadingProperty
 */
