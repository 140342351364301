import { PropertyType } from 'common/Types/Entities/Property/PropertyDto';

/**
 * @type {Array<{name: String, type: PropertyType, value: String, alwaysVisible: Boolean }>}
 */
export const regionProperties = [
  {
    name: 'Datum der Begehung',
    type: PropertyType.DATE,
    value: '',
    alwaysVisible: true
  },
  {
    name: 'Begeher',
    type: PropertyType.TEXT,
    value: '',
    alwaysVisible: true
  },
  {
    name: 'Länge',
    type: PropertyType.TEXT,
    value: '',
    alwaysVisible: true
  },
  {
    name: 'Durchschnittliches Gefälle',
    type: PropertyType.TEXT,
    value: '',
    alwaysVisible: true
  }
];
